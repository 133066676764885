





















































import { Vue, Component, PropSync, Prop } from "vue-property-decorator";
import {
    mdiContentCopy,
    mdiFacebook,
    mdiLogin,
    mdiShare,
    mdiTwitter,
    mdiWhatsapp,
} from "@mdi/js";
import { addAnalyticsEvent } from "@/plugins/firebase";
import { captureSentryException } from "@/plugins/sentry";
import Referral from "@/models/referral";

@Component
export default class ShareAlert extends Vue {
    @PropSync("isLoading", { required: true, type: Boolean }) loading!: boolean;
    @Prop({ required: false, type: Boolean, default: false }) block!: boolean;
    @Prop({ required: false, type: Referral }) referral!: Referral | null;
    @Prop({ required: false, type: Boolean, default: true }) large!: boolean;
    @Prop({ required: false, type: String, default: "mr-n3 py-6" })
    btnClass!: string;
    @Prop({ required: false, type: String, default: "primary" })
    btnColor!: string;
    @Prop({ required: false, type: Boolean, default: false }) tile!: boolean;
    loginIcon: string = mdiLogin;
    shareIcon: string = mdiShare;
    copyIcon: string = mdiContentCopy;
    twitterIcon: string = mdiTwitter;
    facebookIcon: string = mdiFacebook;
    whatsappIcon: string = mdiWhatsapp;

    get twitterLink(): string {
        return `https://twitter.com/intent/tweet?text=${encodeURI(
            `I use @NyangaPay to pay for my DStv subscription online. It is really easy and a lot cheaper! Get a 1000 FCFA discount with my link ${this.referralUrl}`
        )}`;
    }

    async copyReferralLink() {
        try {
            await navigator.clipboard.writeText(this.referralUrl ?? "");
            this.$root.$emit(
                this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                "Your referral link has been copied to your clipboard"
            );
        } catch (error) {
            captureSentryException(error);
        }
    }

    get referralUrl(): string {
        return this.referral?.url ?? "";
    }

    get facebookLink(): string {
        return `https://www.facebook.com/sharer/sharer.php?u=${this.referralUrl}`;
    }

    get whatsappLink(): string {
        return `https://api.whatsapp.com/send?text=${encodeURI(
            `Hey\nI use Nyanga Pay to pay for my DStv subscription online. It is really easy and a lot cheaper! Get a 1000 FCFA discount with my link ${this.referralUrl}`
        )}`;
    }

    openLink(link: string) {
        window.open(link, "_blank");
    }

    registerClick() {
        addAnalyticsEvent("auth_alert_button_clicked");
    }
}
