import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"email-wrapper"},[_c('div',{staticClass:"content-cell"},[_c('div',{staticClass:"f-fallback"},[_c('h1',[_vm._v(" Hello "),_c('span',{domProps:{"innerHTML":_vm._f("htmlDisplayName")(_vm.order.customerName)}}),_vm._v(", ")]),_c('p',{staticClass:"mt-4"},[_vm._v(" Thanks for using "+_vm._s(_vm.$constants.APP.NAME)+". "),(_vm.order.isDstv)?_c('span',[_vm._v(" We have credited your DSTV account ")]):_vm._e(),(_vm.order.isAirtime)?_c('span',[_vm._v(" We have transferred your airtime ")]):_vm._e(),(_vm.order.isEneoPostpaid)?_c('span',[_vm._v(" We have paid your ENEO bill ")]):_vm._e(),(_vm.order.isEneoPrepaid)?_c('span',[_vm._v(" We have bought your ENEO credits ")]):_vm._e(),(_vm.order.isExchange || _vm.order.isCryptoSell)?_c('span',[_vm._v(" We have transferred your money ")]):_vm._e(),_vm._v(" successfully and this is the receipt for your purchase. ")]),(_vm.order.isEneoPrepaid)?_c('eneo-alert',{attrs:{"eneo-code":_vm.order.eneoCode}}):_c('share-alert'),(_vm.order.isDstv)?_c('div',{staticClass:"subtitle-1"},[_vm._m(0)]):_vm._e(),_c('table',{staticClass:"purchase",attrs:{"width":"100%","cellpadding":"0","cellspacing":"0","role":"presentation"}},[_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('table',[_c('tr',[_c('td',{staticClass:"body-2"},[_vm._v("Receipt Number")]),_c('td',{staticClass:"pl-4 body-2 font-weight-bold"},[_c('router-link',{staticClass:"font-weight-bold text-decoration-none",attrs:{"to":{
                                            name: _vm.$constants.ROUTE_NAMES
                                                .ORDERS_SHOW,
                                            params: {
                                                orderId: _vm.order.orderId,
                                            },
                                        }}},[_vm._v(" "+_vm._s(_vm.order.orderReference)+" ")])],1)]),_c('tr',[_c('td',{staticClass:"body-2"},[_vm._v("Payment Date")]),_c('td',{staticClass:"pl-4 body-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.order.createdAt.toDateString())+" ")])]),(_vm.order.customerEmail)?_c('tr',[_c('td',{staticClass:"body-2"},[_vm._v("Email")]),_c('td',{staticClass:"pl-4 body-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.order.customerEmail)+" ")])]):_vm._e(),_c('tr',[_c('td',{staticClass:"body-2"},[(
                                            _vm.order.hasCryptoCurrencyPayment
                                        )?_c('span',[_vm._v(" "+_vm._s(_vm.order.customerPaymentMethod)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.order.customerPaymentMethod)+" ")])]),_c('td',{staticClass:"pl-4 body-2 font-weight-bold"},[(_vm.order.hasMobilePayment)?_c('span',[_vm._v(" "+_vm._s(_vm.order.customerPaymentPhoneNumber)+" ")]):_vm._e(),(_vm.order.hasBankCardPayment)?_c('span',[_vm._v(" "+_vm._s(_vm.order.customerBankCardNumber)+" ")]):_vm._e(),(
                                            _vm.order.hasCryptoCurrencyPayment
                                        )?_c('span',[_vm._v(" "+_vm._s(_vm.order.paymentProviderAmount)+" ")]):_vm._e()])])])])]),_c('tr',[_c('td',{staticStyle:{"vertical-align":"top"},attrs:{"width":"50%","colspan":"2"}},[_c('div',{staticClass:"mt-8 body-2"},[_c('span',{staticClass:"text-h6"},[_vm._v("HOTICE LTD")]),_c('br'),_vm._v(" P.O Box: 526 Buea"),_c('br'),_c('a',{staticClass:"text-decoration-none",attrs:{"target":"_blank","href":'mailto:' + _vm.$constants.APP.SUPPORT_EMAIL}},[_vm._v(" "+_vm._s(_vm.$constants.APP.SUPPORT_EMAIL)+" ")]),_c('br'),_c('a',{staticClass:"text-decoration-none",attrs:{"target":"_blank","href":'https://wa.me/237' + _vm.whatsappPhone}},[_vm._v(_vm._s(_vm.whatsappPhone))])])])]),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('table',{staticClass:"purchase_content"},[_c('tr',[_vm._m(1),(
                                        _vm.order.isMultipleQuantity &&
                                        _vm.order.isDstv
                                    )?_c('th',{staticClass:"purchase_heading",attrs:{"align":"center"}},[_c('p',{staticClass:"f-fallback"},[(
                                                _vm.$vuetify.breakpoint.lgAndUp
                                            )?_c('span',[_vm._v("Months")]):_c('span',[_vm._v("Qty")])])]):_vm._e(),_vm._m(2)]),_c('tr',[_c('td',{staticClass:"purchase_item",attrs:{"width":"70%"}},[_c('span',{staticClass:"f-fallback"},[_vm._v(" "+_vm._s(_vm.order.itemName)+" "),_c('br'),(_vm.order.isDstv)?_c('small',{staticClass:"text--secondary"},[_vm._v(" Smartcard Number: "+_vm._s(_vm.order.smartCardNumber)+" ")]):_vm._e(),(_vm.order.isCanalplus)?_c('small',{staticClass:"text--secondary"},[_vm._v(" Card Number: "+_vm._s(_vm.order.smartCardNumber)+" ")]):_vm._e(),(
                                                _vm.order.isAirtime ||
                                                _vm.order.isCryptoSell ||
                                                _vm.order.isExchange
                                            )?_c('small',{staticClass:"text--secondary"},[_vm._v(" Recipient Phone Number: "+_vm._s(_vm.order.recipientPhoneNumber)+" ")]):_vm._e(),(_vm.order.isEneoPrepaid)?_c('p',{staticClass:"text--secondary text-caption mb-n2"},[_vm._v(" Meter Number: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.order.billId))])]):_vm._e(),(
                                                _vm.order.isEneoPostpaid ||
                                                _vm.order.isCamwater
                                            )?_c('p',{staticClass:"text--secondary text-caption mb-n2"},[_vm._v(" Bill Number: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.order.billId))])]):_vm._e(),(_vm.order.isEneoPrepaid)?_c('p',{staticClass:"text--secondary text-caption mb-n2"},[_vm._v(" ENEO code: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.order.eneoCode))])]):_vm._e(),(_vm.order.isEneoPrepaid)?_c('p',{staticClass:"text--secondary text-caption mb-n2"},[_vm._v(" Units: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.order.meterKiloWatts))])]):_vm._e(),(_vm.order.isCryptoSell)?_c('p',{staticClass:"text--secondary text-caption mb-n2"},[_vm._v(" Amount: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("currency")(_vm.order.creditsAdded)))])]):_vm._e(),(
                                                _vm.order.isCryptoSell &&
                                                _vm.order.usdXafRate
                                            )?_c('p',{staticClass:"text--secondary text-caption mb-n2"},[_vm._v(" Rate: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" 1 USD = "+_vm._s(_vm.order.usdXafRate)+" XAF ")])]):_vm._e()])]),(
                                        _vm.order.isMultipleQuantity &&
                                        _vm.order.isDstv
                                    )?_c('td',{staticClass:"align-center text-center purchase_item",attrs:{"width":"10%"}},[_c('span',{staticClass:"f-fallback"},[_vm._v(" "+_vm._s(_vm.order.quantity)+" ")])]):_vm._e(),_c('td',{staticClass:"align-right purchase_item",attrs:{"width":"20%"}},[(_vm.order.isDstv)?_c('span',{staticClass:"f-fallback"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.order.singleItemAmount))+" ")]):_vm._e(),(_vm.order.isAirtime)?_c('span',{staticClass:"f-fallback"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.order.paymentAmount, _vm.order.paymentCurrency ))+" ")]):_vm._e(),(_vm.order.isCryptoSell)?_c('span',{staticClass:"f-fallback"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.order.paymentAmount, _vm.order.paymentCurrency ))+" ")]):_vm._e(),(
                                            _vm.order.isEneo || _vm.order.isCamwater
                                        )?_c('span',{staticClass:"f-fallback"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.order.paymentAmount - _vm.order.fulfillmentProviderFees)))+" ")]):_vm._e(),(_vm.order.isExchange)?_c('span',{staticClass:"f-fallback"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.order.creditsAdded))+" ")]):_vm._e(),(_vm.order.isCanalplus)?_c('span',{staticClass:"f-fallback"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.order.creditsAdded))+" ")]):_vm._e()])]),(_vm.order.isEneo)?_c('tr',[_vm._m(3),_c('td',{staticClass:"align-right purchase_item purchase_item--fees",attrs:{"width":"30%"}},[_c('span',{staticClass:"f-fallback"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.order.fulfillmentProviderFees))+" ")])])]):_vm._e(),(
                                    _vm.order.isCanalplus &&
                                    _vm.order.hasBankCardPayment
                                )?_c('tr',[_vm._m(4),_c('td',{staticClass:"align-right purchase_item purchase_item--fees",attrs:{"width":"30%"}},[_c('span',{staticClass:"f-fallback"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.order.paymentAmount - _vm.order.creditsAdded)))+" ")])])]):_vm._e(),(_vm.order.isCamwater)?_c('tr',[_vm._m(5),_c('td',{staticClass:"align-right purchase_item purchase_item--fees",attrs:{"width":"30%"}},[_c('span',{staticClass:"f-fallback"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.order.fulfillmentProviderFees))+" ")])])]):_vm._e(),(_vm.order.isExchange)?_c('tr',[_vm._m(6),_c('td',{staticClass:"align-right purchase_item purchase_item--fees",attrs:{"width":"30%"}},[_c('span',{staticClass:"f-fallback"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.order.paymentAmount - _vm.order.creditsAdded)))+" ")])])]):_vm._e(),_c('tr',[_c('td',{staticClass:"purchase_footer",attrs:{"colspan":_vm.order.isMultipleQuantity &&
                                        _vm.order.isDstv
                                            ? 2
                                            : 1,"width":"80%","valign":"middle"}},[_c('p',{staticClass:"f-fallback purchase_total purchase_total--label"},[_vm._v(" Total ")])]),_c('td',{staticClass:"purchase_footer text-right",attrs:{"width":"20%","valign":"middle"}},[_c('p',{staticClass:"f-fallback purchase_total text-right"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.order.paymentAmount, _vm.order.paymentCurrency ))+" ")])])])])])])]),_c('p',[_vm._v(" If you have any questions, reach out to our "),_c('a',{staticClass:"text-decoration-none",attrs:{"href":'mailto:' + _vm.$constants.APP.SUPPORT_EMAIL}},[_vm._v(" support team ")]),_vm._v(" for help via email or send a WhatsApp "),_c(VIcon,{attrs:{"small":"","color":"green"}},[_vm._v(_vm._s(_vm.whatsAppIcon))]),_vm._v(" message to "),_c('a',{staticClass:"text-decoration-none",attrs:{"target":"_blank","href":'https://wa.me/237' + _vm.whatsappPhone}},[_vm._v(_vm._s(_vm.whatsappPhone))]),_vm._v(". ")],1),_c('p',[_vm._v("Cheers, "),_c('br'),_vm._v("The "+_vm._s(_vm.$constants.APP.NAME)+" Team")])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('small',[_c('span',{staticClass:"red--text"},[_vm._v("*")]),_vm._v(" It can take up to 10 minutes for you to see the payment in your DSTV account. ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{staticClass:"purchase_heading",attrs:{"align":"left"}},[_c('p',{staticClass:"f-fallback"},[_vm._v("Description")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{staticClass:"purchase_heading",attrs:{"align":"right"}},[_c('p',{staticClass:"f-fallback"},[_vm._v("Amount")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"purchase_item purchase_item--fees",attrs:{"width":"70%"}},[_c('span',{staticClass:"f-fallback"},[_vm._v(" Eneo transaction fees ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"purchase_item purchase_item--fees",attrs:{"width":"70%"}},[_c('span',{staticClass:"f-fallback"},[_vm._v(" Transaction fees ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"purchase_item purchase_item--fees",attrs:{"width":"70%"}},[_c('span',{staticClass:"f-fallback"},[_vm._v(" Cawater transaction fees ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"purchase_item purchase_item--fees",attrs:{"width":"70%"}},[_c('span',{staticClass:"f-fallback"},[_vm._v(" Transaction fees ")])])}]

export { render, staticRenderFns }