

























































import { Vue, Component } from "vue-property-decorator";
import { mdiLogin } from "@mdi/js";
import { Getter } from "vuex-class";
import { User } from "@/models/user";
import { addAnalyticsEvent } from "@/plugins/firebase";
import ReferralApi from "@/services/api/referral";
import { AxiosError, AxiosResponse } from "axios";
import sentry from "@/plugins/sentry";
import TextLoadingSpinner from "@/components/TextLoadingSpinner.vue";
import ShareButton from "@/components/ShareButton.vue";
import Referral from "@/models/referral";
import { ApiResponse } from "@/services/api/axios";
@Component({
    components: { ShareButton, TextLoadingSpinner },
})
export default class ShareAlert extends Vue {
    loginIcon: string = mdiLogin;
    referralPayoutAmount: number | null = null;
    referralDiscountAmount: number | null = null;
    isLoading = false;
    isLoadingReferral = false;
    referral: Referral | null = null;

    @Getter("user") user!: User | null;
    @Getter("userIsAnonymous") userIsAnonymous!: boolean | null;

    registerClick() {
        addAnalyticsEvent("auth_alert_button_clicked");
    }

    handleError(error: Error) {
        sentry.captureException(error);
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            error.message
        );
    }

    get canRefer(): boolean {
        return this.referral != null;
    }

    handleAxiosError(error: AxiosError<ApiResponse>) {
        this.handleError(
            new Error(error.response?.data?.message ?? error.message)
        );
    }

    loadReferralAmounts() {
        this.isLoading = true;
        ReferralApi.getSettings()
            .then((response: AxiosResponse) => {
                this.referralDiscountAmount =
                    response.data.data.discount_amount;
                this.referralPayoutAmount = response.data.data.payout_amount;
            })
            .catch(this.handleError)
            .finally(() => {
                this.isLoading = false;
            });
    }

    loadReferral() {
        this.isLoadingReferral = true;
        ReferralApi.show(this.user?.uid as string)
            .then((response: AxiosResponse) => {
                this.referral = new Referral(response.data.data);
                this.referralDiscountAmount = this.referral.discountAmount;
                this.referralPayoutAmount = this.referral.payoutAmount;
                this.isLoadingReferral = false;
            })
            .catch(this.handleError);
    }

    mounted() {
        if (this.userIsAnonymous) {
            this.loadReferralAmounts();
        } else {
            this.loadReferral();
        }
    }
}
