





















































































































































































































































































































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import Order from "@/models/order";
import { mdiWhatsapp } from "@mdi/js";
import ShareAlert from "@/components/ShareAlert.vue";
import EneoAlert from "@/components/EneoAlert.vue";
@Component({
    components: { EneoAlert, ShareAlert },
})
export default class OrderReceipt extends Vue {
    @Prop({ required: true, type: Order }) order!: Order;

    whatsAppIcon: string = mdiWhatsapp;

    get whatsappPhone() {
        return process.env.VUE_APP_APP_WHATSAPP_PHONE;
    }
}
