import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({class:_vm.btnClass,attrs:{"color":_vm.btnColor,"disabled":_vm.loading,"tile":_vm.tile,"block":_vm.block}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.shareIcon))]),_vm._v(" Share ")],1)]}}])},[_c(VList,[_c(VListItem,{attrs:{"link":""},on:{"click":_vm.copyReferralLink}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.copyIcon)+" ")]),_vm._v(" Copy Link ")],1)],1),_c(VListItem,{attrs:{"link":""},on:{"click":function($event){return _vm.openLink(_vm.whatsappLink)}}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"color":"green"}},[_vm._v(" "+_vm._s(_vm.whatsappIcon)+" ")]),_vm._v(" Send With WhatsApp ")],1)],1),_c(VListItem,{attrs:{"link":""},on:{"click":function($event){return _vm.openLink(_vm.facebookLink)}}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"color":"blue darken-4"}},[_vm._v(" "+_vm._s(_vm.facebookIcon)+" ")]),_vm._v(" Post To Facebook ")],1)],1),_c(VListItem,{attrs:{"link":""},on:{"click":function($event){return _vm.openLink(_vm.twitterLink)}}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"color":"blue"}},[_vm._v(" "+_vm._s(_vm.twitterIcon)+" ")]),_vm._v(" Tweet ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }