import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAlert,{attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint.mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":""}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"grow",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndDown ? '12' : '0'}},[_c('p',{staticClass:"mb-n1",class:{
                    'text-h5 font-weight-bold': _vm.$vuetify.breakpoint.lgAndUp,
                    'text-h6 font-weight-bold text-center':
                        !_vm.$vuetify.breakpoint.lgAndUp,
                }},[_vm._v(" "+_vm._s(_vm.eneoCode)+" ")])]),_c(VCol,{staticClass:"shrink",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndDown ? '12' : '0'}},[_c(VBtn,{attrs:{"color":"success","block":_vm.$vuetify.breakpoint.mdAndDown},on:{"click":_vm.copyEneoCode}},[_c(VIcon,[_vm._v(_vm._s(_vm.copyIcon))]),_vm._v(" Copy ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }